import React, { Suspense } from 'react';

import { Layout } from '@components/layout';
import { Leave } from '@features/mypage/Leave';

const HistoryPage = () => (
  <Layout>
    <Suspense fallback={<p>Loading...</p>}>
      <Leave />
    </Suspense>
  </Layout>
);

export default HistoryPage;
