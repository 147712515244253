import React, { FC } from 'react';

import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { VStack } from '@components/layout/vstack';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';

export const Leave: FC = () => (
  <Block width="100%" padding="30px 30px">
    <Heading2 fontSize="24px" lh="40px">
      退会手続き
    </Heading2>
    <Paragraph lh="60px">下記注意事項をご確認ください。</Paragraph>
    <Block margin="20px 0">
      <Paragraph fontSize="16px" lh="60px" fontWeight="700">
        会員情報について
      </Paragraph>
      <Paragraph>退会後は会員登録情報を確認することはできません。</Paragraph>
    </Block>
    <Block margin="20px 0">
      <Paragraph fontSize="16px" lh="60px" fontWeight="700">
        クーポンについて
      </Paragraph>
      <Paragraph>取得済のクーポンは使用不可となります。</Paragraph>
    </Block>
    <Block margin="20px 0">
      <Paragraph fontSize="16px" lh="60px" fontWeight="700">
        ご注文商品について
      </Paragraph>
      <Paragraph>既にご注文いただいた商品は、退会後も発送させていただきます。</Paragraph>
    </Block>
    <VStack spacing="20px" py="50px">
      <Button radius="5px" color="white" width="232px" height="50px">
        退会に進む
      </Button>
      <Button radius="5px" width="196px" bg="gray2" border="1px solid #d9d9d9d9">
        TOPページへ戻る
      </Button>
    </VStack>
  </Block>
);
